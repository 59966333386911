import { useEffect } from "react"

const disableViewWidth = 768

const useMainHeadingFontsize = () => {
  useEffect(() => {
    const headings = document.querySelectorAll(".home-page h1")
    const homeTextPanel = document.querySelector(".home-page .panel--text")

    const resetFontStyle = () => {
      headings.forEach(heading => {
        heading.style.fontSize = ""
      })
    }
    const setHeadingSizes = () => {
      if (window.innerWidth <= disableViewWidth) {
        resetFontStyle()
        return
      }

      const textPaddingX = parseInt(
        window.getComputedStyle(homeTextPanel).paddingLeft
      )
      headings.forEach(heading => {
        heading.style.fontSize =
          (window.innerWidth - textPaddingX * 2) * 0.05 + "px"
      })
    }

    setHeadingSizes()
    window.addEventListener("resize", setHeadingSizes)
    return () => {
      window.removeEventListener("resize", setHeadingSizes)
    }
  }, [])
}

export default useMainHeadingFontsize
