import React, { useContext, useEffect, useState } from "react"
import SEO from "../components/seo"
import { useThrottleFn, useWindowSize } from "react-use"
import { Link } from "gatsby"

import TransitionLink from "../components/TransitionLink"
import { toMin2digits } from "../utils/helpers"

import HomepageGallery from "../components/home/HomeGallery"
import ActiveProjectImageCarousel from "../components/home/ActiveProjectImage"
import useMainHeadingFontsize from "../hooks/home/useMainHeadingFontsize"

import "./index.scss"

import Img from "../assets/images/projects-images"
import RightArrowSVG from "../assets/svg/right-arrow.svg"
import ChachaVideo from "../assets/images/chacha/cha-1A.mp4"

export const PROJECT_DATA = [
  {
    title: "Double-O Consultants",
    business: "Consultants",
    url: "/projects/double-o/",
    image: "doubleo",
    color: "#dd574c",
    tagline:
      "Brand strategy and implementation for a business consultancy that’s anything but corporate. Strategy, name, brand identity, tone of voice, key messaging and content, photography, website, design collateral.",
  },

  {
    title: "Orbica Digital Geography",
    business: "",
    url: "/projects/orbica/",
    image: "orbica",
    color: "#2b2b2b",

    tagline:
      "Strategy and positioning for a bold challenger brand in the digital geography industry. Brand strategy, name, logomark, design platform, bespoke website, digital outputs, tone of voice, key messaging and copy content, brand and marketing collateral. ",
  },
  {
    title: "Cha Cha Cafe",
    business: "",
    color: "#db5566",
    url: "/projects/cha-cha/",
    image: null,
    video: ChachaVideo,
    tagline: `A brand and interior fit-out that gave a London café its buzz.
      Brand strategy, name, logo, design platform, interior fit-out, collateral.
      `,
  },
  {
    title: "Au Natural Skinfood",
    business: "",
    color: "#9fc979",
    url: "/projects/au-natural/",
    image: "aunatural",
    tagline: `An extended brand platform and storytelling that took a sustainable
    New Zealand beauty product range to market. Design platform, website, photography,
    tone-of-voice, key messaging, copy content.`,
  },
  {
    title: "First Law Lawyers",
    business: "",
    url: "/projects/first-law/",
    color: "#5856a0",
    image: "firstlaw",
    tagline:
      "A disruptive approach for a progressive law firm that works in ways that put people first. Strategy, name, brand identity, tone of voice, key messaging and content, photography, website, interior fit-out, collateral.",
  },
  {
    title: "KO & CO Gardens & Landscapes",
    business: "",
    color: "#d3455f",
    url: "/projects/ko-and-co/",
    image: "koco",
    tagline:
      "Dynamic branding for a landscape business led by the most colourful identity in the industry. Strategy, illustration platform, name, logo, website, tone-of-voice and copy, marketing collateral.",
  },
  {
    title: "Ezimac Mac Service & Sales",
    color: "#e3e3e3",
    business: "",
    url: "/projects/ezimac/",
    image: "ezimac",
    tagline:
      "A Mac-styled brand platform for a provider that takes away the IT headaches and makes everything Mac better. Strategy, brand identity, tone of voice, key messaging and content, website, fit-out design, design collateral.",
  },
  {
    title: "Red & Black Construction",
    business: "",
    color: "#de5034",
    url: "/projects/red-and-black/",
    image: "redandblack",
    tagline:
      "A rebrand for the architectural construction company behind head-turning builds. Strategy, brand identity, tone of voice, key messaging and content, photography, website, collateral.",
  },
  {
    title: "Southern Creations Furniture",
    business: "",
    url: "/projects/southern-creations/",
    image: "southerncreations",
    color: "#516046",
    tagline:
      "The rebranding of an icon of Canterbury furniture design and manufacturing. Brand strategy, logomark, brand platform, website design, tone-of-voice, key messaging and copy content, brand and marketing collateral.",
  },

  {
    title: "Environment Canterbury",
    business: "",
    url: "/projects/ecan/",
    image: "ecan",
    color: "#3D6078",
    tagline:
      " User interface design for a rates tool that talks to the community. Strategy, wireframing, UI &amp; UX design.",
  },
]

const projects = [...PROJECT_DATA]

const IntroSection = () => {
  return (
    <div className="intro-section page-wrapper">
      <SEO
        title="Home"
        description="Friday Creative is a brand development agency from Christchurch, New
    Zealand. We use bold and strategic design, creative and storytelling
    to make businesses look good, feel right and do well."
      />
      <div className="panel panel--text bg-white">
        <h1>Be real.</h1>
        <h1>Be brave.</h1>
        <h1>Be bloody good.</h1>
        <p>
          Friday Creative is a brand development agency from Christchurch, New
          Zealand. We use bold and strategic design, creative and storytelling
          to make businesses look good, feel right and do well.
        </p>
      </div>
      <div className="panel">
        <HomepageGallery />
      </div>
    </div>
  )
}

const ProjectsSection = () => {
  const { width: windowWidth } = useWindowSize()
  const isSingleColumnLayout = windowWidth <= 768

  const [activeProject, setActiveProject] = useState(projects[0])

  const [value, setValue] = useState(projects[0])

  useThrottleFn(
    value => {
      setActiveProject(value)
    },
    650,
    [value]
  )

  if (typeof window === `undefined`) {
    return <></>
  }

  if (isSingleColumnLayout)
    return (
      <div className="page-wrapper project-section-mobile">
        {projects.map(project => (
          <div className="project" key={project.title}>
            <TransitionLink to={project.url} color={project.color}>
              {project.image && (
                <Img className="project__image" src={project.image} />
              )}
              {project.video && (
                <video
                  src={project.video}
                  autoPlay
                  playsInline
                  muted
                  loop
                  className="object-cover"
                ></video>
              )}
            </TransitionLink>
            <div className="project__mobile-text-container">
              <h2>{project.title}</h2>
              <p>{project.tagline}</p>
              <TransitionLink to={project.url} color={project.color}>
                <h3 className="view-full-site display-inline">
                  View Project{" "}
                  <RightArrowSVG
                    className="inline-block"
                    style={{ width: "0.4em", marginLeft: "0.2em" }}
                  />
                </h3>
              </TransitionLink>
            </div>
          </div>
        ))}
      </div>
    )

  return (
    <div className="projects-section page-wrapper" id="projects">
      <div className="panel">
        <ActiveProjectImageCarousel
          projects={projects}
          activeProject={activeProject}
        />
        {/* <HomepageGallery /> */}
      </div>
      <div className="panel panel--text bg-white">
        <h2>Featured Projects:</h2>
        <div className="projects-list-container">
          {projects.map((project, index) => (
            <TransitionLink
              to={project.url}
              color={project.color}
              key={index}
              // preventDefault={project !== activeProject}
            >
              <div
                className={`list-item ${value === project ? "active" : ""}`}
                onMouseEnter={() => {
                  setValue(project)
                }}
              >
                <div className="list-item__number">
                  {toMin2digits(index + 1)}
                </div>
                <div className="list-item__title">
                  {project.title}
                  <span className="underscore">_</span>
                </div>
              </div>
            </TransitionLink>
          ))}
        </div>
      </div>
    </div>
  )
}

const HomePage = () => {
  useMainHeadingFontsize()

  return (
    <div className="home-page">
      <IntroSection />
      <ProjectsSection />
    </div>
  )
}

export default HomePage
