import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const HomeImage = ({ src, className = "", style }) => {
  const data = useStaticQuery(graphql`
    query {
      homepage1: file(relativePath: { eq: "home/openingimage1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homepage2: file(relativePath: { eq: "home/openingimage2.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homepage3: file(relativePath: { eq: "home/openingimage3.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homepage4: file(relativePath: { eq: "home/openingimage4.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homepage5: file(relativePath: { eq: "home/openingimage5.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homepage6: file(relativePath: { eq: "home/openingimage6.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homepage7: file(relativePath: { eq: "home/openingimage7.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homepage8: file(relativePath: { eq: "ecan/ecan-6.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ezimacAward: file(relativePath: { eq: "home/award.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
  }
  const eagerImages = [
    "homepage1",
    "homepage2",
    "homepage3",
    "homepage4",
    "homepage5",
    "homepage6",
    "homepage7",
    "homepage8",
  ]

  const loading = eagerImages.includes(src) ? "eager" : "lazy"

  return (
    <GatsbyImage
      fluid={data[src].childImageSharp.fluid}
      className={`img--${src} ${className}`}
      style={style}
      loading={loading}
    />
  )
}
export default HomeImage
