import React, { useEffect, useState, useContext } from "react"
import { useInterval, useMeasure } from "react-use"
import { motion, AnimatePresence } from "framer-motion"

import { defaultTransition as transition } from "../../utils/easings"
import TransitionLink from "../TransitionLink"

import HomeImage from "../../assets/images/home-images"

const galleryImages = [
  // { link: "/projects/ezimac/", image: "ezimacAward", color: "#e3e3e3" }, // award
  { link: "/projects/ko-and-co/", image: "homepage7", color: "#d3455f" },
  { link: "/projects/red-and-black/", image: "homepage6", color: "#de5034" },
  { link: "/projects/ezimac/", image: "ezimacAward", color: "#e3e3e3" }, // award
  { link: "/projects/double-o/", image: "homepage2", color: "#dd574c" },
  { link: "/projects/orbica/", image: "homepage1", color: "#2b2b2b" },
  { link: "/projects/ezimac/", image: "ezimacAward", color: "#e3e3e3" }, // award
  { link: "/projects/au-natural/", image: "homepage3", color: "#9fc979" },
  { link: "/projects/ezimac/", image: "homepage4", color: "#e3e3e3" },
  { link: "/projects/ezimac/", image: "ezimacAward", color: "#e3e3e3" }, // award
  { link: "/projects/first-law/", image: "homepage5", color: "#5856a0" },
  { link: "/projects/ecan", image: "homepage8" },
]

const SLIDE_VARIANTS = {
  initial: {
    height: "100%",
    zIndex: 10,
    transition,
  },
  active: {
    height: "100%",
    zIndex: 11,
    transition,
  },
  exit: {
    height: "0%",
    zIndex: 12,
    transition,
  },
}

const useGalleryController = () => {
  const [galleryIndex, setGalleryIndex] = useState(0)
  const [isActive, setIsActive] = useState(true)
  const [gallerySlideDuration, setGallerySlideDuration] = useState(3000)

  const nextSlide = () => {
    setGalleryIndex((galleryIndex + 1) % galleryImages.length)
  }

  useInterval(() => nextSlide(), isActive ? gallerySlideDuration : null)

  useEffect(() => {
    return () => {
      setIsActive(false)
    }
  }, [])

  return [galleryIndex]
}

const PreloadContainer = () => {
  return (
    <div className="absolute inset-0 z-0 overflow-hidden opacity-0 preload-container">
      {galleryImages.map((img, index) => (
        <div className="absolute inset-0" key={index}>
          <HomeImage src={img.image} className="h-full" />
        </div>
      ))}
    </div>
  )
}

const HomepageGallery = () => {
  const [galleryIndex] = useGalleryController()
  const [ref, { height: galleryHeight }] = useMeasure()

  return (
    <div className="home-gallery-container" ref={ref}>
      <PreloadContainer />
      <AnimatePresence>
        <motion.div
          key={galleryImages[galleryIndex].image}
          className="slide-container"
          variants={SLIDE_VARIANTS}
          initial={"initial"}
          animate={"active"}
          exit={"exit"}
        >
          <TransitionLink
            to={galleryImages[galleryIndex].link}
            color={galleryImages[galleryIndex].color}
          >
            <HomeImage
              src={galleryImages[galleryIndex].image}
              className="image"
              style={{ height: galleryHeight }}
            />
          </TransitionLink>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default HomepageGallery
