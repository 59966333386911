import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const ProjectsImages = ({ src, className = "", style }) => {
  const data = useStaticQuery(graphql`
    query {
      doubleo: file(relativePath: { eq: "doubleO/do-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      chacha: file(relativePath: { eq: "chacha/cha-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aunatural: file(relativePath: { eq: "au-natural/au-0.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      orbica: file(relativePath: { eq: "orbica/orb-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      koco: file(relativePath: { eq: "koandco/ko-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      firstlaw: file(relativePath: { eq: "first-law/fl-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ezimac: file(relativePath: { eq: "ezimac/ez-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      redandblack: file(relativePath: { eq: "redandblack/rb-1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      southerncreations: file(
        relativePath: { eq: "southern-creations/sc-1.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ecan: file(relativePath: { eq: "ecan/ecan-6.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
  }
  const eagerImages = [
    "doubleo",
    "chacha",
    "aunatural",
    "orbica",
    "koco",
    "firstlaw",
    "ezimac",
    "redandblack",
    "southerncreations",
    "ecan",
  ]

  const loading = eagerImages.includes(src) ? "eager" : "lazy"
  return (
    <GatsbyImage
      fluid={data[src].childImageSharp.fluid}
      className={`img--${src} ${className}`}
      style={style}
      loading={loading}
    />
  )
}
export default ProjectsImages
