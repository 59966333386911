import React, { useEffect, useState, useContext } from "react"
import { useInterval, useMeasure } from "react-use"
import { motion, AnimatePresence } from "framer-motion"

import { defaultTransition as transition } from "../../utils/easings"
import TransitionLink from "../TransitionLink"

import ProjectsImages from "../../assets/images/projects-images"

const SLIDE_VARIANTS = {
  initial: {
    zIndex: 10,
    transition: { ...transition, duration: 0.6 },
  },
  active: {
    height: "100%",
    zIndex: 11,
    transition: { ...transition, duration: 0.6 },
  },
  exit: {
    height: "0%",
    zIndex: 12,
    transition: { ...transition, duration: 0.6 },
  },
}

const PreloadContainer = ({ projects }) => {
  return (
    <div className="preload-container absolute inset-0 z-0 opacity-0 overflow-hidden">
      {projects.map((project, index) => {
        if (project.image)
          return (
            <div className="absolute inset-0" key={index}>
              <ProjectsImages src={project.image} className="h-full" />
            </div>
          )

        if (project.video)
          return (
            <video
              className="absolute inset-0"
              key={index}
              src={project.video}
            ></video>
          )
      })}
    </div>
  )
}

const ActiveProjectImageCarousel = ({ projects, activeProject }) => {
  const [ref, { height: containerHeight }] = useMeasure()

  return (
    <div className="projects-gallery-container" ref={ref}>
      <PreloadContainer projects={projects} />
      <AnimatePresence>
        <motion.div
          key={activeProject.image}
          className="slide-container"
          variants={SLIDE_VARIANTS}
          initial={"initial"}
          animate={"active"}
          exit={"exit"}
        >
          <TransitionLink to={activeProject.url} color={activeProject.color}>
            {activeProject.image && (
              <ProjectsImages
                src={activeProject.image}
                className="image"
                style={{ height: containerHeight }}
              />
            )}

            {activeProject.video && (
              <video
                src={activeProject.video}
                autoPlay
                playsInline
                muted
                loop
                className="object-cover relative"
                style={{ height: containerHeight }}
              ></video>
            )}
          </TransitionLink>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default ActiveProjectImageCarousel
